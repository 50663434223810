import React from "react"
import { graphql } from "gatsby"
import { SEO, Layout, PostOptions } from "../components/index"

// Declaring query here allows us to shadow components
export const query = graphql`
  query ($slug: String!, $author: String!) {
    sanityAuthors(slug: { current: { eq: $slug } }) {
      name
      biography
      socialLinks {
        icon {
          asset {
            gatsbyImageData(width: 48)
          }
        }
        label
        url
      }
      donate
      slug {
        current
      }
    }

    sanityPages(templateKey: { eq: "blog" }) {
      slug {
        current
      }
    }

    categories: allSanityCategories {
      ...categories
    }

    authors: allSanityAuthors {
      ...authors
    }

    posts: allSanityPosts(filter: { author: { name: { eq: $author } } }) {
      ...posts
    }
  }
`

const AuthorTemplate = ({ data }) => {
  let author = data.sanityAuthors
  let items = data.posts.edges
  let parent = data.sanityPages.slug.current
  let categories = data.categories.nodes
  let authors = data.authors.nodes

  for (var item in items) {
    if (items[item].node) {
      items[item] = {
        title: items[item].node.title,
        author: items[item].node.author,
        description: `${items[item].node.excerpt}..`,
        category: items[item].node.category,
        slug: { current: `/${parent}/${items[item].node.slug.current}` },
        publishedAt: items[item].node.publishedAt,
        mainImage: items[item].node.mainImage,
        itemImage: { _type: "image" },
      }
      delete items[item].node
    }
  }

  items = { items, numColumns: "one" }

  return (
    <Layout>
      {author.name && <h1>Author—{author.name}</h1>}
      {/*{author.biography && <h2>{author.biography}</h2>}*/}
      <PostOptions
        categories={categories}
        author={author}
        authors={authors}
        posts={items}
      />
    </Layout>
  )
}

export default AuthorTemplate

export function Head({ data }) {
  let { title, description } = data.sanityAuthors

  return <SEO title={title} description={description} />
}
